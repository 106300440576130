import React, { useState } from 'react';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import firstStepValue from './initialValues';
import { Footer } from 'layouts/Main/components';
import { Typography } from '@mui/material';
import PasswordResetCover from 'views/PasswordResetCover';
import secondStepData from 'views/PasswordResetCover/secondStepData';
import { Box } from '@mui/material';
import About from 'views/About/About';

  const SignupCover = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [firstState, setFirstState] = useState(firstStepValue);
    const [secondState, setSecondState] = useState(secondStepData);
  
  
    const handleCurrentStep = (newStep, data) => {
      if(newStep === 2 && data) {
        setFirstState(data);
      }
      if(newStep === 3 && data){
      }
      setCurrentStep(newStep);
    };
   
    return (
      <>
       
      {/* <div style={{ height: 40, backgroundColor: '#377dff', padding: '10px' }}>
        <Typography
        style={{ float: 'left' }}
        variant={'subtitle2'}
        color={'white'}
      >NPS YPR</Typography>
        <a style={{ float: 'right' }} href="/signup-cover" >
          <img 
          height={20}
          width={20}
          color={'white'}
          src='https://cdn-icons-png.flaticon.com/512/25/25376.png'/>
        </a>
      </div>
      <br />
      <br /> */}

   
     
        <Container paddingTop={'0 !important'}
          sx={{
            background:
              'url(https://www.npshsr.com/img/school/hsr_scholastic3.jpg) no-repeat center',
            backgroundSize: 'cover',
            marginTop: -3,
            paddingTop: 12,
          }}>
          {currentStep === 1 && (
            <Form
              handleCurrentStep={handleCurrentStep}
              firstState={firstState}
            />
          )}
          {currentStep === 2 && (
            <PasswordResetCover handleCurrentStep={handleCurrentStep}
            firstState={firstState}
            secondState={secondState} />
          )}
           {/* {currentStep === 3 && (
            <About />
          )} */}
        </Container>
        <Container maxWidth={0.9}>
        <Footer />
      </Container>
    </>
    );
  };

export default SignupCover;
