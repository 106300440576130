/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { alpha, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const validationSchema = yup.object({
  email_id: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

// const axios = require('axios').default;
let isDisabled = true;

const Form = ({ handleCurrentStep, firstState }) => {
  const [captchaCode, setCaptchaCode] = useState('');
  const onSubmit = () => {
    axios
      .post('http://204.236.192.144:49159/api/account/send-otp', formik.values)
      .then((response) => {
        if (response.data != null) {
          console.log('Values', formik.values);
          handleCurrentStep(2, formik.values);
        }
      });

    return formik.values;
  };

  const onChange = (valueCaptcha) => {
    setCaptchaCode(valueCaptcha.length);
  };

  const formik = useFormik({
    initialValues: firstState,
    validationSchema: validationSchema,
    onSubmit,
  });

  if (
    formik &&
    formik.values &&
    formik.values.email_id &&
    Object.keys(formik.errors).length === 0 &&
    captchaCode
  ) {
    isDisabled = false;
  }

  return (
    <Box maxWidth={600} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          align={'center'}
          gutterBottom
        >
          National Public School
        </Typography>
      </Box>
      <Box>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email_id"
                variant="outlined"
                color="primary"
                size="medium"
                name={'email_id'}
                fullWidth
                defaultValue={formik.values.email_id}
                onChange={formik.handleChange('email_id')}
                error={
                  formik.touched.email_id || Boolean(formik.errors.email_id)
                }
                helperText={formik.touched.email_id || formik.errors.email_id}
              />
            </Grid>
            <br />
            <Grid item container justifyContent={'center'} xs={12}>
              <Box alignContent={'center'}>
                <ReCAPTCHA
                  sitekey="6LdnjA8iAAAAAL0iFw5FQzAk1rnONk2acKVt19a-"
                  onChange={onChange}
                />
              </Box>
            </Grid>
            <br />

            <Box
              component={Button}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              height={54}
              marginTop={{ xs: 4, sm: 4 }}
              marginLeft={{ xs: 15, sm: 38 }}
              disabled={isDisabled}
              readOnly={isDisabled}
              onClick={onSubmit}
              // href = '/startup'
            >
              Verify
            </Box>
          </Grid>
        </div>
      </Box>
    </Box>
  );
};

export default Form;
